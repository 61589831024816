// https://www.google.com/design/spec/components/tabs.html#tabs-specs

.nav-link {
  //line-height: $bmd-nav-link-line-height; // set baseline line-height and font sizes
  //text-transform: uppercase;
}

// navbars
.navbar-nav {
  .nav-link {
    padding: $bmd-navbar-link-padding;
    font-size: $bmd-navbar-link-font-size;
    font-weight: $bmd-navbar-link-font-weight;
  }
}

// pills and tabs
.nav-tabs,
.nav-pills {
  border: 0;

  .nav-link {
    padding: $bmd-nav-tabs-pills-link-padding;
    font-size: $bmd-nav-tabs-pills-font-size;
    font-weight: $bmd-nav-tabs-pills-font-weight;
    border: 0;
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    background-color: transparent;
    color: inherit;
  }
}

// tabs only
.nav-tabs {
  .nav-link {
    border-bottom: $bmd-nav-tabs-border-size solid transparent;
  }

  // colors
  @include bmd-tabs-color($bmd-nav-tabs-color, $bmd-nav-tabs-active-color, $bmd-nav-tabs-active-border-color, $bmd-nav-tabs-disabled-link-color, $bmd-nav-tabs-disabled-link-color-hover);

  &.bg-primary {
    @include bmd-tabs-color($bmd-nav-tabs-primary-color, $bmd-nav-tabs-primary-active-color, $bmd-nav-tabs-primary-active-border-color, $bmd-nav-tabs-primary-disabled-link-color, $bmd-nav-tabs-primary-disabled-link-color-hover);
  }

  &.bg-dark {
    @include bmd-tabs-color($bmd-nav-tabs-inverse-color, $bmd-nav-tabs-inverse-active-color, $bmd-nav-tabs-inverse-active-border-color, $bmd-nav-tabs-inverse-disabled-link-color, $bmd-nav-tabs-inverse-disabled-link-color-hover);
  }
}

// used to correctly size ripple container
.nav {
  .nav-item {
    position: relative;
  }
}
