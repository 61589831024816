.custom-file-control {
  @extend .form-control;
  @include bmd-form-color($bmd-label-color, $bmd-label-color-focus, $input-border-color);

  &::before {
    @extend .btn;
    position: absolute;
    height: calc(100% - 1px);
  }
}
