.progress {
  height: 4px;
  background: #c8c8c8;
  border-radius: 0;
  box-shadow: none;
  .progress-bar {
    box-shadow: none;
    //@include variations(unquote(".progress-bar"), unquote(""), background-color, $brand-primary);
  }
}
