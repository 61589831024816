// marker class (used as a selector for one-off elements to decorate)
.ripple {
  position: relative;
}

.ripple-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  border-radius: inherit;

  .ripple-decorator {
    position: absolute;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    pointer-events: none;
    background-color: rgba($black, 0.05);
    border-radius: 100%;
    opacity: 0;
    transform: scale(1);
    transform-origin: 50%;

    &.ripple-on {
      opacity: 0.1;
      transition: opacity 0.15s ease-in 0s,
        transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
    }

    &.ripple-out {
      opacity: 0;
      transition: opacity 0.1s linear 0s !important;
    }
  }
}
