::selection
  color: #fff
  background: $primary-color

/* #main-body
    margin: 1rem 0 */

/* Page */

.page-main
    margin: 2em 0

/* Footer */

.footer-logo
    width: 20rem
    height: 100%

footer
    height: 100%
    padding: 2rem 0 1rem 0

.bg-softgrey
    background-color: #efefef !important

.footer-link
    display: block
    color: $body-color
    margin: 0.35rem 0

.footer-headline
    display: block
    margin: 0.35rem 0

/* Navbar */

.logo
    width: 20rem
    height: 100%

.btn-nav
    margin: 0 0 0 1rem

.navbar
    box-shadow: none

/* Back to TOP */

#return-to-top
    position: fixed
    bottom: 20px
    right: 20px
    display: none
    transition: all 0.3s ease
    z-index: 99999

#return-to-top i
    color: #fff
    position: relative
    font-size: 19px
    transition: all 0.3s ease

#return-to-top:hover
    background: rgba(0, 0, 0, 0.9)

#return-to-top:hover i
    color: #fff
    top: -5px

/* SVG Images */

.svg-img
    max-width: 80%
    height: auto

    @media screen and (max-width: 999px)
        max-width: 92%

.svg-smaller
    max-width: 72%

    @media screen and (max-width: 999px)
        max-width: 92%

.align-items-start .svg-img
    margin-top: 10rem

/* Lottie */

.lottie
    max-width: 100%
    overflow: hidden
    svg
        width: 160% !important
        margin: 0 0 0 -30%

/* Hero */

.hero
    position: relative

.hero-content
    margin: 4rem 0 3rem 0

    h2
        margin: 0 0 4rem 0

.hero-divider, .sections-divider
    position: absolute
    top: 0
    left: 0
    right: 0

    @media screen and (min-width: 1540px)
        transform: scaleY(0.6)
        transform-origin: top left

    @media screen and (min-width: 1840px)
        transform: scaleY(0.45)
        transform-origin: top left

    svg
        display: block
        width: 100%
        position: relative
        left: 0
        top: 0

        path
            fill: #f5f5f5

.hero-divider svg
    top: -14rem

/* Home Sections */

.sections-with-divider
    position: relative

.sections-divider svg path
    fill: #fff

.home-sections
    padding: 4rem 0

.home-sections-content

    h2
        margin: 5rem 0 2rem 0

    h6
        margin: 5rem 0 0.5rem 0

    h6 + h2
        margin: 0rem 0 2rem 0

    .btn
        margin: 1rem 0 2rem 0

/* Kontakt */

.kontaktrow

    .kontaktrow-content
        padding-left: 60px

        &:nth-child(1)
            @media screen and (max-width: 768px)
                margin-bottom: 2em

        .kontaktrow-description
            font-family: $font-family-sans-serif
            font-weight: $font-weight-normal

.kontaktrow-container
     &:nth-child(1)
        @media screen and (max-width: 768px)
            margin-bottom: 2em

.kontaktrow.fas::before
    position: absolute
    font-size: 2.6rem
    color: $primary-color

/* Formcheck honeypot */

input#emailWdh
    display: none

/* Cards */

.card-bkf
    margin: 1rem 0
    background-image: url("/img/card-clip.svg")
    background-repeat: no-repeat
    background-position: right bottom
    background-size: 2rem
    box-shadow: none !important

    &:hover
        background-color: $primary-color !important
        color: white

/* Diverses */

.ado-numbered-list
    list-style: none
    padding: 0

    li
        padding: 0

        span
            color: $primary-color
            margin: 0.5rem 1rem

            strong
                color: #fff

@media screen and (max-device-width: 800px)
    .logo, .navebar-brand
        max-width: 68vw


.section-weiterbildung
    .row
        padding: 4em 0
    a
        text-decoration: underline
        color: #212529
    ul
        list-style: none
        li
            background-position: 0 0
            background-repeat: no-repeat
            padding-left: 3.7em
            &.socials-linkedin
                background-image: url("/img/linkedin-icon.svg")
            &.socials-xing
                background-image: url("/img/xing-icon.svg")
            &.socials-themenetzwerke
                background-image: url("/img/themennetzwerke-icon.svg")
        ul
            border-left: 1px solid #D8D8D8
            li
                padding-left: 0
    h3
        a
            color: $primary
            &::after
                content: " 🡵"
            &:hover
                color: #212529
    a.btn
        text-decoration: none

.kontakt-flyer
    padding: 2.5em 1em 2.5em 7em
    a
        margin-top: 1em

.row.flyer-download
    padding: 0 0

.row.weiterbildungs-cols
    column-gap: 5em
    padding-top: 125px
    @media screen and (max-width: 1200px)
        column-gap: 1em
    @media screen and (max-width: 992px)
        column-gap: 0
        padding-top: 0
    a
        color: $primary-color
        font-weight: bold
        &:hover
            color: #212529
    .col
        display: flex
        flex-direction: column
        @media screen and (max-width: 992px)
            flex-basis: 100%
        h3
            flex-basis: 2.7em
            @media screen and (max-width: 992px)
                flex-basis: auto
                margin: 1em 0 0.6em 0
        p
            flex-grow: 1
        &:before
            content: " "
            background-color: $primary
            background-position: center center
            background-repeat: no-repeat
            width: 100px
            height: 100px
            position: absolute
            top: calc(-100px - 1.6em)
            left: 1em
            border-radius: 50px
            @media screen and (max-width: 992px)
                display: none
    .kompetenz
        &:before
            background-image: url("/img/kompetenzmanagement-icon.svg")
    .learning
        &:before
            background-image: url("/img/learning-management-system-icon.svg")
    .nachhaltigkeit
        &:before
            background-image: url("/img/nachhaltigkeit-icon.svg")

.wbv-kontakt
    padding: 2.5em 1em 2.5em 7em
    background-image: url("/img/kontakt-icon.svg")
    background-repeat: no-repeat
    background-position: 3em center
    background-size: 2rem
    box-shadow: none !important

a#video
    max-width: 100%
    overflow: hidden

#video-servicetechniker
    max-width: 100%