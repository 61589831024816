.radio label,
label.radio-inline {
  position: relative;
  padding-left: $bmd-radio-size + $bmd-radio-label-padding; // absolutely positioned so add the radio size

  .bmd-radio {
    position: absolute;
    left: 0;
    display: inline-block;
    padding: .7em;
    margin: -.7em;
    line-height: .7;
    border-radius: 100%;

    &::after {
      display: inline-block;
      width: $bmd-radio-size;
      height: $bmd-radio-size;
      cursor: pointer;
      content: "";
      border: $bmd-radio-border solid $bmd-radio-color-off;
      border-radius: 50%;
      transition: border-color ease .28s;
      transition-duration: 0.2s;
    }
    &::before {
      position: absolute;
      display: inline-block;
      width: $bmd-radio-size;
      height: $bmd-radio-size;
      content: "";
      background-color: $bmd-radio-color-on;
      border-radius: 50%;
      transition: transform ease .28s;
      transform: scale3d(0, 0, 0);
    }
  }

  input[type=radio] {
    // Hide native radio
    position: absolute;
    left: 0;
    z-index: -1;
    width: 0;
    height: 0;
    margin: 0;
    overflow: hidden;
    pointer-events: none;
    opacity: 0;

    &:checked ~ .bmd-radio {
      @include bmd-radio-color($bmd-radio-color-on);

      &::before {
        transform: scale3d(0.5, 0.5, 1);
      }
    }

    &[disabled],
    &:disabled,
    fieldset[disabled] & {
      + .bmd-radio {
        cursor: default;
        @include bmd-radio-color($bmd-radio-color-disabled);
      }

      // No ripple on disabled radios
      + .bmd-radio .ripple-container {
        display: none;
      }

      .bg-inverse & + .bmd-radio {
        @include bmd-radio-color($bmd-radio-color-disabled-inverse);
      }
    }
  }
}
