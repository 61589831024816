// Animations (from mdl http://www.getmdl.io/)

@mixin material-animation-fast-out-slow-in($duration:0.2s) {
  transition-duration: $duration;
  transition-timing-function: $bmd-animation-curve-fast-out-slow-in;
}

@mixin material-animation-linear-out-slow-in($duration:0.2s) {
  transition-duration: $duration;
  transition-timing-function: $bmd-animation-curve-linear-out-slow-in;
}

@mixin material-animation-fast-out-linear-in($duration:0.2s) {
  transition-duration: $duration;
  transition-timing-function: $bmd-animation-curve-fast-out-linear-in;
}

@mixin material-animation-default($duration:0.2s) {
  transition-duration: $duration;
  transition-timing-function: $bmd-animation-curve-default;
}
