.navbar {
  z-index: 1; // required to make the shadow overlap the below content (need is visible in mobile drawer overlay)
  border: 0;
  border-radius: 0;
  @include box-shadow($bmd-shadow-2dp);

  // give correct size to ripple container
  .navbar-brand {
    position: relative;
  }

  > .container {
    flex: 1;
  }
}

// Needed to properly style inputs on dark background
.navbar-dark .form-control {
  color: $white;
  &::placeholder {
    color: $navbar-dark-color;
  }
}
