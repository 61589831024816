//https://www.google.com/design/spec/components/cards.html#cards-content-blocks
// Card resting elevation: 2dp
.card {
  border: 0;

  // Cards have a default elevation of 2dp.
  @include box-shadow($bmd-shadow-2dp);
  @extend %std-font;

  // spec: see "Avatar, Title, and Subtitle area"
  // Height: 72dp
  // Padding: 16dp
  // Top and bottom padding: 16dp
  .card-header {
    padding: 1rem;
    background-color: transparent;
  }

  .card-block {
    padding: 1.5rem 1rem 1rem;
    //@extend %std-font;
  }

  // Primary title/text
  //  - Title: 24sp or 14sp
  //  - Subtitle: 14sp

  // non-h* card-titles will be 14sp
  //&.card-title {
  //  @extend %std-font;
  //}

  // any h* will signal the larger size should render as 24sp
  @include headings() {
    &.card-title {
      font-size: 1.5rem;
      font-weight: 300;
    }
  }

  .bmd-card-actions,
  // needed?  if you have actions in the middle of the card (see spec),
  it may be necessary
  .card-footer {
    // assume footer is the set of actions?
    padding: .5rem;
  }

  &.bmd-card-raised {
    // Card raised elevation: 8dp
    @include box-shadow($bmd-shadow-8dp);
  }

  @include media-breakpoint-up(lg) {
    // On desktop, cards can have a resting elevation of 0dp and gain an elevation of 8dp on hover.
    &.bmd-card-flat {
      box-shadow: none;
    }
  }
}
